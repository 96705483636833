import { TYPES_ADRRESS } from '../const/typeAdrress'

export const removeDot = ({ string }) => {
    return String(string).replace(/[$.]/g, '')
}

export const formatMoney = ({ number }) => {
    const total = []
    const onlyNumber = removeDot({ string: number })
    const numberSplit = onlyNumber.split('').reverse()
    numberSplit.forEach((item, index) => {
        total.push(item)
        if (Number(index + 1) % 3 === 0) {
            total.push('.')
        }
    })

    if (['.'].includes(total[total.length - 1])) total.pop()

    return total.reverse().join('')
}

export const createUrlShare = ({ decode = true } = {}) => {
    if (decode) return encodeURIComponent(window.location.href)

    return window.location.href
}

export const getFullAddress = ({ address }) => {
    const result = {}

    address.forEach((item) => {
        result[TYPES_ADRRESS[item.types[0]]] = item.long_name
    })

    return result
}

export const getRandomString = ({ bytes }) => {
    const randomValues = new Uint8Array(bytes)
    window.crypto.getRandomValues(randomValues)
    return Array.from(randomValues).map(intToHex).join('')
}

export const intToHex = (nr) => {
    return nr.toString(16).padStart(2, '0')
}

export const validateEmail = ({ email }) => {
    const re =
        /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/

    return re.test(String(email).toLowerCase())
}

export const encodeFilterToBase64 = ({ filter }) => {
    const jsonString = JSON.stringify(filter)
    return window.btoa(jsonString)
}

export const decodeFilterFromBase64 = ({ base64String, defaultR = [] }) => {
    try {
        const jsonString = window.atob(base64String)
        return JSON.parse(jsonString)
    } catch (error) {
        return defaultR
    }
}

export const updateUrlWithFilter = ({ listParams }) => {
    const url = new URL(window.location.href)

    url.search = ''

    listParams.forEach((param) => {
        url.searchParams.set(param.key, param.value)
    })

    window.history.replaceState(null, '', url)
}

export const formatPossibleNumbersInString = (str) => {
    if (typeof str !== 'string') {  
        return str
    }
  
    return str.replace(/\d+/g, (match) => {
        return match.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    })
}

export const formatNumber = (value) => {
    if (value === null || value === undefined) {
        return value
    }

    if (typeof value === 'number') {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }

    return formatPossibleNumbersInString(value)
}