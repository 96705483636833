import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Components
import { LoadingPost } from '../../components/Loading'
import { RenderMarkDown } from '../../components/RenderMarkDown'
import { SocialShare } from '../../components/SocialShare'
import { Maps } from '../../components/Maps'
import { PropertyGalery } from '../Property/Components/PropertyGalery'
import { LoadingDetailProperty } from '../../components/Loading'
import { SliderGalery } from '../Property/Components/SliderGalery'
import { PropertyContact } from '../Property/Components/PropertyContact'
import { FeaturedPropertiesPrice } from '../../components/FeaturedPropertiesList'
import { TitleIcon } from '../../components/Title'
import { LotFeatured } from '../../components/LotFeatured'
import { FeaturedPropertiesLocation } from '../../components/FeaturedPropertiesList'
import { ShowFeatured } from '../../components/ShowFeatured'
import { Text } from '../../components/Text'

// Icons
import { InfoIcon, ArrowRightIcon, ArrowLeftIcon } from '../../components/Svg'

// Fields
import { FIELD_PARCELLING } from '../../utils/fields'

// Hooks
import { useParcellings, useOpenAndCloseHook } from '../../hooks'

// Helpers
import { scrollTop } from '../../helpers/utils'
import { IGNORER_BASIC_INFO_PARCELLING } from '../../utils/ignorerItems'
import { TRANS_FIELDS_PARCELLING } from '../../utils/transFields'

// Utils
import { createUrlShare } from '../../utils/helpers'

const SHOW_MUNICIPALITY = [FIELD_PARCELLING.location]

const SHOW_DIFERENT = [...SHOW_MUNICIPALITY]

const Parcelling = () => {
    const { id } = useParams()
    const { parcelingInfo, loadingParcellings, getParceling } = useParcellings()
    const [openGalery, setOpenGalery] = useOpenAndCloseHook()
    const [stepSliderHeader, setStepSliderHeader] = useState(0)

    useEffect(() => {
        (async () => {
            try {
                scrollTop()
                await getParceling({ id })
            } catch (e) {
                console.error(e)
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const handleChangeSliderHeader = ({ move }) => {
        const leng = parcelingInfo.attributes[FIELD_PARCELLING.imgHeader].data.length

        if (move >= leng) return setStepSliderHeader(0)

        if (move < 0) return setStepSliderHeader(leng - 1)

        setStepSliderHeader(move)
    }

    const showDetailDiferent = ({ field }) => {
        if (SHOW_MUNICIPALITY.includes(field))
            return parcelingInfo.attributes[field].data.attributes.Nombre

        return ''
    }

    if (loadingParcellings) return <LoadingPost />

    const showItems = Object.keys(parcelingInfo.attributes).filter(
        (field) =>
            !IGNORER_BASIC_INFO_PARCELLING.includes(field) &&
            !!parcelingInfo.attributes[field],
    )

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>TierrasGP | {parcelingInfo.attributes[FIELD_PARCELLING.name]}</title>
                <link rel='canonical' href={createUrlShare({ decode: false })} />

                <meta name="title" content={`TierrasGP | ${parcelingInfo.attributes[FIELD_PARCELLING.name]}`} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={createUrlShare({ decode: false })} />
                <meta property="og:title" content={`TierrasGP | ${parcelingInfo.attributes[FIELD_PARCELLING.name]}`} />
                <meta property="og:image" content={parcelingInfo.attributes[FIELD_PARCELLING.imgPrimary].data.attributes.url} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={createUrlShare({ decode: false })} />
                <meta property="twitter:title" content={`TierrasGP | ${parcelingInfo.attributes[FIELD_PARCELLING.name]}`} />
                <meta property="twitter:image" content={parcelingInfo.attributes[FIELD_PARCELLING.imgPrimary].data.attributes.url}></meta>
            </Helmet>
            <div className='parcelling_hero'>
                <div className='parcelling_hero_left'>
                    <div className="parcelling_hero_left_title">
                        <Text color='white' size='xl'>{parcelingInfo.attributes[FIELD_PARCELLING.name]} {parcelingInfo.attributes[FIELD_PARCELLING.code] ? <span className='span_small'>- {parcelingInfo.attributes[FIELD_PARCELLING.code]}</span> : ''}</Text>
                    </div>
                    <FeaturedPropertiesLocation
                        size='xl'
                        color='white'
                        iconFull
                        location={`${
                            parcelingInfo.attributes[FIELD_PARCELLING.location].data
                                .attributes.Nombre
                        } - ${parcelingInfo.attributes[FIELD_PARCELLING.acueducto]}`}
                    />
                    <RenderMarkDown color='white'>
                        {parcelingInfo.attributes[FIELD_PARCELLING.description]}
                    </RenderMarkDown>

                    <SocialShare text='Compartir :' link face whats />

                    <div className='parcelling_hero_arrow_content'>
                        <div
                            className='parcelling_hero_arrow'
                            onClick={() =>
                                handleChangeSliderHeader({ move: stepSliderHeader - 1 })
                            }
                        >
                            <ArrowLeftIcon color='white' />
                        </div>
                        <div
                            className='parcelling_hero_arrow'
                            onClick={() =>
                                handleChangeSliderHeader({ move: stepSliderHeader + 1 })
                            }
                        >
                            <ArrowRightIcon color='white' />
                        </div>
                    </div>
                </div>
                <div className='parcelling_hero_right'>
                    {parcelingInfo?.attributes[FIELD_PARCELLING.imgHeader]?.data && (
                        <img
                            src={
                                parcelingInfo.attributes[FIELD_PARCELLING.imgHeader].data[
                                    stepSliderHeader
                                ].attributes.url
                            }
                        />
                    )}
                </div>
            </div>

            <div className='container'>
                <div className='content property'>
                    {loadingParcellings ? (
                        <LoadingDetailProperty />
                    ) : (
                        <>
                            {parcelingInfo.attributes[FIELD_PARCELLING.imgPrimary] &&
                            parcelingInfo.attributes[FIELD_PARCELLING.galery].data && (
                                <PropertyGalery
                                    imgPrimary={
                                        parcelingInfo.attributes[FIELD_PARCELLING.imgPrimary]
                                    }
                                    galery={parcelingInfo.attributes[FIELD_PARCELLING.galery]}
                                    onOpenGalery={setOpenGalery}
                                />
                            )}

                            <div className='property_info'>
                                <div className='property_item'>
                                    <FeaturedPropertiesPrice
                                        text={parcelingInfo.attributes[FIELD_PARCELLING.rangePrice]}
                                    />
                                    <TitleIcon
                                        sizeIcon='xl'
                                        icon={<InfoIcon />}
                                        title='Información basica'
                                    />
                                    <RenderMarkDown>
                                        {
                                            parcelingInfo.attributes[
                                                FIELD_PARCELLING.basicInformation
                                            ]
                                        }
                                    </RenderMarkDown>

                                    <ShowFeatured
                                        data={parcelingInfo.attributes}
                                        showDetailDiferent={showDetailDiferent}
                                        showDiferent={SHOW_DIFERENT}
                                        showItems={showItems}
                                        transField={TRANS_FIELDS_PARCELLING}
                                    />

                                    {parcelingInfo?.attributes[
                                        FIELD_PARCELLING.embeddedVideo
                                    ] && (
                                        <div
                                            className='property_video'
                                            dangerouslySetInnerHTML={{
                                                __html:
                                            parcelingInfo?.attributes[
                                                FIELD_PARCELLING.embeddedVideo
                                            ],
                                            }}
                                        ></div>
                                    )}
                                    {parcelingInfo.attributes[FIELD_PARCELLING.lat] &&
                                    parcelingInfo.attributes[FIELD_PARCELLING.lng] && (
                                        <div className='property_map'>
                                            <Maps
                                                lat={parcelingInfo.attributes[FIELD_PARCELLING.lat]}
                                                lng={parcelingInfo.attributes[FIELD_PARCELLING.lng]}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className='property_item'>
                                    <PropertyContact />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {parcelingInfo.attributes[FIELD_PARCELLING.lotes].data.length > 0 && (
                <LotFeatured
                    title='Lotes recomendados'
                    description='Sé el primero en conocer estos Lotes antes que muchas personas.'
                    listLot={parcelingInfo.attributes[FIELD_PARCELLING.lotes].data}
                />
            )}

            {openGalery && (
                <SliderGalery
                    galery={[
                        parcelingInfo.attributes[FIELD_PARCELLING.imgPrimary].data,
                        ...parcelingInfo.attributes[FIELD_PARCELLING.galery].data,
                    ]}
                    close={setOpenGalery}
                />
            )}
        </>
    )
}

export default Parcelling
